import React from "react";
import {graphql} from "gatsby";
import styled from "styled-components";
import Layout from "../components/Layout";
/* import PreviewCompatibleImage from "../components/PreviewCompatibleImage"; */
/* import {HTMLContent} from "../components/Content"; */

const Root = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: center;
    /* align-items: center; */
    width: 960px;
    height: 70%;
`;

/* const BackgroundImage = styled(PreviewCompatibleImage)`
    width: 100%;
    min-height: 650px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    display: flex;
    justify-content: center;
    align-items: center;
`; */

const Title = styled.h1`
    color: ${({theme}) => theme.palette.blue[7]};
    font-size: ${({theme}) => theme.fontSize[8]};
`;

const Description = styled.p`
    color: ${({theme}) => theme.palette.blue[7]};
    font-size: ${({theme}) => theme.fontSize[5]};
    margin: 0;
`;

type TTemplateProps = {
    title: string;
    description: string;
};

export const IndexPageTemplate: React.FC<TTemplateProps> = ({title, description}) => (
    <Root>
        {/* <BackgroundImage imageInfo={image} type="background">
            <div>
                <Title>{title}</Title>
            </div>
        </BackgroundImage> */}
        <Title>{title}</Title>
        <Description>{description}</Description>
    </Root>
);

type TProps = {
    path: string;
    data: any;
};

const IndexPage: React.FC<TProps> = ({path, data}) => {
    const {frontmatter} = data.markdownRemark;

    return (
        <div>
            <Layout path={path}>
                <IndexPageTemplate
                    /* contentComponent={HTMLContent} */
                    title={frontmatter.title}
                    description={frontmatter.description}
                    /* image={frontmatter.image} */
                />
            </Layout>
        </div>
    );
};

export default IndexPage;

export const pageQuery = graphql`
    query IndexPageTemplate {
        markdownRemark(frontmatter: {templateKey: {eq: "index-page"}}) {
            frontmatter {
                title
                description
            }
        }
    }
`;
