import React from "react";
import styled from "styled-components";

const Root = styled.footer``;

const Footer: React.FC = () => {
    return (
        <Root>
            {/* © {new Date().getFullYear()}, Built with <a href="https://www.gatsbyjs.org">Gatsby</a> */}
        </Root>
    );
};

export default Footer;
