import React from "react";
import styled from "styled-components";

const StyledA = styled.a`
    border-radius: 6px;
    border: 2px solid;
    border-color: ${({theme}) => theme.palette.blue[7]};
    color: ${({theme}) => theme.palette.blue[7]};
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    /* font-weight: 700; */
    padding: ${({theme}) => theme.spacing[1]} ${({theme}) => theme.spacing[2]};
`;

type TProps = {
    href: string;
    children: React.ReactNode;
};

const LinkButton: React.FC<TProps> = ({href, children}) => {
    return (
        <StyledA type="button" href={href}>
            {children}
        </StyledA>
    );
};

export default LinkButton;
