import React from "react";
import styled from "styled-components";
import LinkButton from "./LinkButton";

const Root = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    padding: ${({theme}) => theme.spacing[1]};
`;

type TProps = {
    siteTitle?: string;
    path?: string;
};

const NavMenu: React.FC<TProps> = ({siteTitle}) => {
    return (
        <Root>
            <div />
            {siteTitle}
            <LinkButton href="https://dib.no">Logg inn</LinkButton>
        </Root>
    );
};

export default NavMenu;
