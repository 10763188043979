import React from "react";
import styled, {ThemeProvider} from "styled-components";
/* import {useStaticQuery, graphql} from "gatsby"; */
import NavMenu from "./NavMenu";
import Footer from "./Footer";
import theme from "../config/theme";

const Root = styled.div`
    display: flex;
    flex-flow: column;
    justify-content: space-between;
    height: 100vh;
`;

const Content = styled.main`
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    margin: 0 auto;
    max-width: 960px;
    padding: 0 1.0875rem 1.45rem;
`;

type TProps = {
    path?: string;
    children: React.ReactNode;
};

const Layout: React.FC<TProps> = ({path, children}) => {
    /* const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `); */

    return (
        <ThemeProvider theme={theme}>
            <Root>
                <NavMenu path={path} />
                <Content>
                    {/* <main>{children}</main> */}
                    {children}
                </Content>
                <Footer />
            </Root>
        </ThemeProvider>
    );
};

export default Layout;
